<template>
  <van-nav-bar
  title="出库列表"
  left-arrow
  @click-left="onGoBack"
  />
  <!--<table>
    <th style="width:33%">产品名称</th><th>收货人</th><th>出货日期</th>
    <tr style=""
      v-for="item in exwarerecord.data"
      :key="item.id"
      >
      <td>{{item.proc_name}}</td>
      <td>{{item.get_proc_people}}</td>
      <td>{{item.get_proc_time}}</td>
    </tr>

  </table>-->
  
  <van-cell v-for="item in exwarerecord.data" :key="item"  is-link @click="getinfo(item)">
    <van-icon name="todo-list-o" />
    <span style="margin-left:20px;color:#09adee">{{item.proCode}}</span>
    <span style="margin-left:20px">{{item.conName}}</span>
    <span style="margin-left:20px">{{item.recordTime}}</span>
  </van-cell>

  <van-pagination 
    v-model="currentPage"
    :total-items="pagination.totalitems" 
    :items-per-page="pagination.itemsperpage" 
    @change="onChangePage"
  />

</template>

<script>
import { reactive,onMounted,getCurrentInstance} from 'vue'
import { useUserStore } from '@/store/user.ts'

const base_url=process.env.VUE_APP_API_PORT_BASE_URL
export default {
  setup() {
    const exwarerecord = reactive({data: [{}]});
    let { proxy } = getCurrentInstance();
    let pagination=reactive({
      totalitems:null,
      currentpage:1,
      itemsperpage:15,
    });

    onMounted(() => {
      getexwareinfo()
    });
    const onChangePage=()=>{
       pagination.currentpage=currentPage.value
      //pager.current = pagination.current;
        getexwareinfo()
    }
    const userStore = useUserStore()
    const getexwareinfo=()=>{
      let params = {data: {}}
      let tokens={'Authorization':userStore.userInfo.token}
      proxy.$axios.post(base_url+'/delivery/getDeliveryRecord',params,{headers:tokens})
      .then(res=>{
        exwarerecord.data.length=0;
        console.log(res)
        for (var i=0;i<res.data.data.length;i++) {
          exwarerecord.data.push(res.data.data[i])
        }
        pagination.totalitems = res.data.data.total;
        //pagination.total = 200;
        pagination.itemsperpage=15;
      }).catch(err=>{
        
        console.log('err'+err)

      })
    };

    return {
      exwarerecord,
      pagination,
      getexwareinfo,
      onChangePage
    };
  },
};
  
</script>
<style lang="less">




</style>
